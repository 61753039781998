import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import Web3Status from "components/Web3Status";
import { NftVariant, useNftFlag } from "featureFlags/flags/nft";
import { chainIdToBackendName } from "graphql/data/util";
import { Box } from "nft/components/Box";
import { Row } from "nft/components/Flex";
import { IntrinsicIcon } from "nft/components/icons";
import { ReactNode } from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";

import { ChainSelector } from "./ChainSelector";
import { MenuDropdown } from "./MenuDropdown";
import { SearchBar } from "./SearchBar";
import { ShoppingBag } from "./ShoppingBag";
import * as styles from "./style.css";
import { useTheme } from "styled-components";
import { darkTheme } from "nft/themes/darkTheme";

interface MenuItemProps {
  href: string;
  id?: NavLinkProps["id"];
  isActive?: boolean;
  children: ReactNode;
}

const MenuItem = ({ href, id, isActive, children }: MenuItemProps) => {
  const theme = useTheme();

  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: "none", color: theme.navTextColor }}
    >
      {children}
    </NavLink>
  );
};

const PageTabs = () => {
  const { pathname } = useLocation();
  const nftFlag = useNftFlag();
  const { chainId: connectedChainId } = useWeb3React();
  const chainName = chainIdToBackendName(connectedChainId);

  const isPoolActive =
    pathname.startsWith("/pool") ||
    pathname.startsWith("/add") ||
    pathname.startsWith("/remove") ||
    pathname.startsWith("/increase") ||
    pathname.startsWith("/find");

  return (
    <>
      <MenuItem href="/swap" isActive={pathname.startsWith("/swap")}>
        <Trans>Swap</Trans>
      </MenuItem>
      {/* <MenuItem href={`/tokens/${chainName.toLowerCase()}`} isActive={pathname.startsWith('/tokens')}>
        <Trans>Tokens</Trans>
      </MenuItem> */}
      {nftFlag === NftVariant.Enabled && (
        <MenuItem href="/nfts" isActive={pathname.startsWith("/nfts")}>
          <Trans>NFTs</Trans>
        </MenuItem>
      )}
      <MenuItem href="/pool" id={"pool-nav-link"} isActive={isPoolActive}>
        <Trans>Pool</Trans>
      </MenuItem>
    </>
  );
};

const Navbar = () => {
  const { pathname } = useLocation();
  const isNftPage =
    pathname.startsWith("/nfts") || pathname.startsWith("/profile");

  return (
    <>
      <nav className={styles.nav}>
        <Box
          display="flex"
          height="full"
          flexWrap="nowrap"
          alignItems="stretch"
        >
          <Box className={styles.leftSideContainer}>
            <Box as="a" href="#/swap" className={styles.logoContainer}>
              <IntrinsicIcon width="48" height="48" className={styles.logo} />
            </Box>
            {!isNftPage && (
              <Box display={{ sm: "flex", lg: "none" }}>
                <ChainSelector leftAlign={true} />
              </Box>
            )}
            <Row gap="8" display={{ sm: "none", lg: "flex" }}>
              <PageTabs />
            </Row>
            {/* <div className={styles.searchBarContainerDiv}>
              <SearchBar />
            </div> */}
          </Box>
          <Box className={styles.rightSideContainer}>
            <Row gap="16">
              <Box display={{ sm: "flex", xl: "none" }}>
                <SearchBar />
              </Box>
              <Box display={{ sm: "none", lg: "flex" }}>
                <MenuDropdown />
              </Box>
              {isNftPage && <ShoppingBag />}
              {!isNftPage && (
                <Box display={{ sm: "none", lg: "flex" }}>
                  <ChainSelector />
                </Box>
              )}

              <Web3Status />
            </Row>
          </Box>
        </Box>
      </nav>
      <Box className={styles.mobileBottomBar}>
        <PageTabs />
        <Box marginY="4">
          <MenuDropdown />
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
